import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import classnames from 'classnames';
import Input from '../../../ui/Input';

const MIN_LIMIT = 0;

const LimitCell = ({
  alertCategoryPermission,
  data,
  groupActive,
  updateRow,
}) => {
  const [value, setValue] = useState(data.limit);

  // case creation toggle can also affect the limit, so update accordingly
  useEffect(() => {
    setValue(data.limit);
  }, [data.limit]);

  const changeLimit = val => {
    const valid = val === '' || val >= MIN_LIMIT;
    if (val === '') {
      val = value;
    }
    setValue(val);
    if (valid) updateRow(data, val, undefined);
  };

  return (
    <Cell
      className={classnames('settings__table-cell', {
        inactive: !groupActive,
      })}
      data-cy="settings-7-day-input"
    >
      <Input
        bordered
        disabled={data.limit === 0 || !alertCategoryPermission}
        error={value < MIN_LIMIT}
        font="xs"
        min={MIN_LIMIT}
        name="limit"
        onChange={changeLimit}
        placeholder="Default"
        readOnly={!data.enabled || !alertCategoryPermission}
        type="number"
        value={value}
      />
    </Cell>
  );
};

LimitCell.propTypes = {
  alertCategoryPermission: PropTypes.bool,
  data: PropTypes.shape({
    enabled: PropTypes.bool,
    limit: PropTypes.number,
  }),
  groupActive: PropTypes.bool,
  updateRow: PropTypes.func,
};

LimitCell.defaultProps = {
  alertCategoryPermission: false,
  data: {},
  groupActive: false,
  updateRow: () => {},
};

export default LimitCell;
